import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;

    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0 0 2rem;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }
    }
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Mexiko - jak neutratit víc, než je potřeba</Title>
            <Date>18. 3. 2023</Date>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/mexico/mexico-illustration.jpg" alt=""></StaticImage>
                <StyledFigcaption><a href="https://www.freepik.com/free-photo/closeup-worried-woman-having-one-dollar-bill-her-wallet_25630559.htm?fbclid=IwAR2zUlN8tFwOSoIG8N020DzK3JxcoMBmcKTJe7cRvuqIDfWA5fSTgfYRmoI#query=money%20travel&position=6&from_view=search&track=ais">Image by Drazen Zigic</a> on Freepik</StyledFigcaption>
            </StyledFigure>
            <p>Na cestě vždy narazíte na férové lidi. Bohužel občas i na takové, kteří se snaží využít Vaší neznalosti ve svůj prospěch. Projela jsem mnoho zemí a mohu s klidným svědomím říct, že podvody a podrazy se dějí všude - od Evropy, přes Asii, až po Ameriku. Svět není vždy jen o hodných lidech. Naštěstí je těch poctivých více a většina cest je plná hezkých zážitků. Je však dobré být připraven i na ty horší a nepříjemné situace. Tady je pár rad na základě osobní zkušenosti:</p>
                <BlogList>
                    <li>
                        <h3>Neměňte peníze na letišti</h3>
                        <p>Kurz byl velmi nevýhodný oproti kurzům ve městě, většinou cca 2 pesos na 1 USD. Pokud máte Revolut nebo účet u banky, která nabízí výběry po světě zdarma, raději si na letišti vyberte z bankomatu. Dejte si však pozor na místní poplatky, může to být 0-120 pesos</p>
                    </li>
                    <li>
                        <h3>Pořiďte si SIM kartu ve Wallmartu</h3>
                        <p>Pokud chcete mít stálé připojení k internetu, nejlépe se to v cizině řeší nákupem místní SIM karty. Dělám to tak vždy za hranicemi EU - neváhám si jí zakoupit hned na letišti. Toto doporučení však neplatí pro Mexiko, kde mají na letišti ceny znatelně vyšší. Stejně tak se nenechte ošálit návštěvou prvního elektro obchodu na hlavní třídě. Nám se vyplatilo zakoupit místní SIM v obchodě Wallmart. Za pouhých 200 pesos (v přepočtu asi 240 CZK) jsme měli na 30 dní neomezená data na sociální sítě - Instagram, Facebook, WhatsApp... Na ostatní věci (mapy, internetový prohlížeč atd.) jsme měli k dispozici 1,5 GB.</p>
                    </li>
                    <li>
                        <h3>Na trzích smlouvejte</h3>
                        <p>Místní jsou na to zvyklí. Většinou je reálná cena na cca 60-75 %. Samozřejmě záleží na vás, na kolik si danou věc hodnotíte. Na smlouvání se nedá aplikovat přesné pravidlo, ale rozhodně se ve většině případů vyplatí.</p>
                    </li>
                    <li>
                        <h3>U stánků s jídlem se ptejte na ceny dopředu</h3>
                        <p>To stejné platí i v místních podnicích (kavárny, restaurace). Pokud nemají vyvěšený ceník, požádejte o menu nebo se před objednávkou na cenu zeptejte. Vyvarujete se tak toho, že dodatečně vymyslí nesmyslnou částku a vám bude hloupé říci, že to občerstvení, které držíte v ruce, nechcete.</p>
                    </li>
                    <li>
                        <h3>Při vracení si kontroluje peníze</h3>
                        <p>Je jedno jestli platíte na místním trhu nebo ve větším obchodě (Oxxo, Wallmart…). Bohužel jsme byli svědky toho, kdy špatně vrátili turistům ve frontě před námi a následně i nám. Netvrdím, že je to pravidlo, ale děje se to a obezřetnou kontrolou nic nezkazíte.</p>
                    </li>
                    <li>
                        <h3>Nenechte si vnutit spropitné</h3>
                        <p>Někde Vás budou ubezpečovat, že musíte zaplatit spropitné 18-20 %. To se nám ale stalo jen 2x během 17 dní na 1400 km dlouhé cestě. Ve většině restaurací nám vraceli do pesos přesně a my si dýško zvolili sami podle spokojenosti. Najdou se však i výjimky, kdy vám na účtenku rovnou uvedou 15, 18, nebo dokonce 20 %. Připravte se na to, že pokud toto spropitné odmítnete zaplatit, může dojít na konfrontaci ze strany číšníků - někdy dost nepříjemnou. Nenechte se přesvědčit, opravdu není nutné vyžadovanou částku zaplatit a je pouze na vás kolik jim necháte. Spropitné je dobrovolné a odvíjí se od kvality služeb, kterých se vám dostane. Osobně nemám problém nechat i víc, když mi chutná a obsluha je milá a příjemná. Naopak když dostanu průměrné jídlo a z obsluhy mám dojem, jako by je moje přítomnost otravovala… Výši dýška si zkrátka a dobře může zvolit každý podle sebe.</p>
                    </li>
                </BlogList>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
